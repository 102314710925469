<template>
  <div>
    <a-form-model
      ref="addForm"
      :model="addForm"
    >
      <div class="goods-mod">
        <div class="header">
          <h3 class="tit">基础信息</h3>
        </div>
        <div class="main">
          <a-row>
            <a-col :span="10">
              <a-form-model-item class="c-form-model-item" label="商品类目"
                prop="category"
                :rules="{
                  required: addForm.editCategory,
                  message: '请选择商品类目',
                  trigger: 'change',
                }"
              >
                <span v-if="!addForm.editCategory">{{detailData.categoryName}}</span>
                <a-button type="link" v-if="!addForm.editCategory" @click="addForm.editCategory = true">修改类目</a-button>
                <a-cascader
                  v-if="addForm.editCategory"
                  :fieldNames="{
                    label: 'tagName',
                    value: 'tagId',
                    children: 'children'
                  }"
                  :options="treeData"
                  change-on-select
                  placeholder="请选择商品类目"
                  v-model="addForm.category"
                  :getPopupContainer="node => node.parentNode || document.body"
                />
                <!-- <a-button type="link" v-if="addForm.editCategory" @click="addForm.editCategory = false">取消修改</a-button> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-model-item class="c-form-model-item" label="所属企业" 
                prop="company"
                :rules="{
                  required: true,
                  message: '请选择所属企业',
                  trigger: 'change',
                }"
              >
                <a-select
                  show-search
                  v-model="addForm.company"
                  placeholder="请输入企业关键字"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  :allow-clear="true"
                  @search="handleCompanySearch"
                  @change="handleCompanyChange"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
                  <a-select-option v-for="d in companyData" :key="d.companyId">
                    {{ d.companyName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="10" :offset="2">
              <a-form-model-item class="c-form-model-item" label="所属品牌">
                <a-input v-model="addForm.brand" placeholder="请输入品牌(选填)" />
                <!-- <a-select
                  show-search
                  v-model="addForm.brand"
                  placeholder="请输入品牌关键字(选填)"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  :allow-clear="true"
                  @search="handleBrandSearch"
                  @change="handleBrandChange"
                  :getPopupContainer="node => node.parentNode || document.body"
                >
                  <a-select-option v-for="d in brandData" :key="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-form-model-item class="c-form-model-item" label="商品主图" required
            prop="fileList"
            :rules="{
              validator: validateMainPic,
              trigger: 'change',
            }"
          >
            <file-upload
              name="files"
              :action="uploadFileApi"
              :data="uploadData"
              list-type="picture"
              :file-list="addForm.fileList"
              class="upload-list-inline"
              @preview="handlePreview"
              :custom-before-upload="customBeforeUpload"
              @change="handleUploadChange"
              accept="image/*"
              :limit="{
                type: {
                  reg: /^image\/.*/,
                  msg: '请上传图片格式的图片'
                },
                size: {
                  max: 20,
                  msg: '图片的大小不能超过20M'
                }
              }"
            >
              <a-button> <a-icon type="upload" /> 上传 </a-button>
              <span class="tip">建议上传3MB以内jpg、jpeg、png格式图片，最多上传9张图片</span>
            </file-upload>
          </a-form-model-item>
        </div>
      </div>

      <div class="goods-mod" v-for="(single_item, single_index) in addForm.singleList" :key="single_item.id">
        <div class="header">
          <h3 class="tit">单品{{chineseNumberConf[single_index+1]}}</h3>
          <a-button size="small" @click="delSingle(single_index)" v-if="addForm.singleList.length > 1"><i class="e-icon delete"></i>删除</a-button>
        </div>
        <div class="main">
          <a-button class="quote-btn" type="link" size="small" @click="openQuoteGoodsBox(single_index)">
            <i class="e-icon copy-blue"></i>商品引用
          </a-button>
          <h4 class="main-mod-tit">商品属性</h4>
          <div class="main-mod-wrap">
            <a-row>
              <a-col :span="10">
                <a-form-model-item class="c-form-model-item" label="商品名称"
                  :prop="'singleList.' + single_index + '.singleName'"
                  :rules="{
                    required: true,
                    message: '请输入商品名称',
                    trigger: 'blur',
                  }"
                >
                  <a-input v-model="single_item.singleName" placeholder="请输入商品名称"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10" :offset="2">
                <a-form-model-item class="c-form-model-item" label="商品型号" 
                  :prop="'singleList.' + single_index + '.singleCode'"
                  :rules="{
                    required: true,
                    message: '请输入商品型号',
                    trigger: 'blur',
                  }"
                >
                  <a-input placeholder="请输入商品型号" v-model="single_item.singleCode"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item class="c-form-model-item" label="主色" required
              :prop="'singleList.' + single_index + '.colorArr'"
              :rules="{
                validator: validateColorArr,
                trigger: 'change',
              }"
            >
              <div class="color-form-item" v-for="(item, index) in single_item.colorArr" :key="item.id">
                <i class="color-checked"
                  title="点击删除"
                  v-if="item.color.length>0"
                  @click="colorItemDel(item, index, single_index)"
                ></i>
                <!-- <i class="color-checked unchecked" v-else></i> -->
                <a-input
                  class="color-input"
                  v-model.trim="item.color"
                  placeholder="请输入主色"
                  @input="e => colorItemInput(e, item, index, single_index)"
                  @focus="e => colorItemFocus(e, item, index, single_index)"
                  @blur="colorItemBlur"
                ></a-input>
                <span>
                  <!-- <img class="color-img" v-if="item.url" :src="item.url" alt=""> -->
                  <a-popover v-if="item.url" placement="right" overlayClassName="img-popover"
                    :getPopupContainer="node => node.parentNode || document.body"
                  >
                    <template slot="content">
                      <img class="color-img-big" :src="item.url">
                    </template>
                    <img class="color-img" :src="item.url">
                  </a-popover>
                  <file-upload
                    name="files"
                    :action="uploadFileApi"
                    :data="uploadData"
                    @change="info => handleColorImgChange(info, item, single_index)"
                    class="color-img-upload"
                    accept="image/*"
                    :limit="{
                      type: {
                        reg: /^image\/.*/,
                        msg: '请上传图片格式的图片'
                      },
                      size: {
                        max: 20,
                        msg: '图片的大小不能超过20M'
                      }
                    }"
                  >
                    <a-button type="link" style="padding: 0 4px;">{{item.url ? '替换' : '选择图片'}}</a-button>
                  </file-upload>
                  <a-button type="link" v-if="item.url" @click="item.url = ''" style="padding: 0 4px;">删除</a-button>
                </span>
                <div class="error" v-if="item.err">该颜色已存在！</div>
              </div>
            </a-form-model-item>
            <a-form-model-item class="c-form-model-item" label="尺码" required
              :prop="'singleList.' + single_index + '.sizeArrSelect'"
              :rules="{
                validator: validatesizeArrSelect,
                trigger: 'change',
              }"
            >
              <a-checkbox-group v-model="single_item.sizeArrSelect" style="display: inline" @change="sizeChangeHandle(single_index)">
                <span style="margin: 0 16px 16px 0;display: inline-block;" v-for="(size_item) in single_item.sizeArr" :key="size_item.id">
                  <a-checkbox :value="size_item.name">
                    {{size_item.name}}
                  </a-checkbox>
                  <template v-if="!size_item.isDefault">
                    <i class="e-icon edit" style="cursor: pointer" title="编辑"
                      @click="editSizeHandle(size_item, single_index)"></i>
                    <i class="e-icon delete" style="cursor: pointer" title="删除"
                      @click="deleteSizeHandle(size_item, single_item, single_index)"></i>
                  </template>
                </span>
              </a-checkbox-group>
              <a-input-search
                style="width: 150px"
                placeholder="请输入自定义值"
                enter-button="添加"
                size="small"
                v-model="single_item.addSizeVal"
                @search="value => addSizeHandle(value, single_item, single_index)"
              />
              <br>
              <a-button type="link" style="padding: 0" @click="openSortProp(single_index)">开始排序</a-button>
            </a-form-model-item>
            <a-form-model-item class="c-form-model-item" label="商品描述" 
              :prop="'singleList.' + single_index + '.richContent'"
              :rules="{
                required: true,
                message: '请输入商品描述',
                trigger: 'change',
              }"
            >
              <div style="width: 860px; margin-bottom: 8px">
                <editor bucket-name="yijiajia-item-image" 
                  v-model="single_item.richContent" 
                  :dataUpdated="single_item.richDataUpdated"
                  @change="$refs.addForm.validateField('singleList.' + single_index + '.richContent')"
                />
              </div>
            </a-form-model-item>

            <a-form-model-item class="c-form-model-item" :label="cont.key" v-for="cont in single_item.tempProp && single_item.tempProp['1']" :key="cont.id">
              <custom-form-item :form-type="cont.type" :value.sync="cont.value" :range="cont.data" :unit="cont.unit"/>
            </a-form-model-item>

          </div>
          
          <!-- 分隔线 -->
          <div class="divider"></div>

          <h4 class="main-mod-tit">销售属性</h4>

          <div class="main-mod-wrap">
            <a-button v-if="single_item.skusChart.length > 0" class="fill-btn" type="link" size="small" @click="openBatchFill(single_index)">
              <i class="e-icon fill-blue"></i>批量填充
            </a-button>
            <a-form-model-item class="c-form-model-item" label="销售规格">
              <table class="sku-table">
                <thead>
                  <tr class="th">
                    <th>颜色</th>
                    <th>颜色编码</th>
                    <th>尺码</th>
                    <th>出厂价格(元)</th>
                    <th>批发价格(元)</th>
                    <th>零售价格(元)</th>
                    <th><span style="color:#f5222d">*</span> 吊牌价格(元)</th>
                    <th>尾货价格(元)</th>
                  </tr>
                </thead>
                <tbody v-for="(c_item, c_index) in single_item.skusChart" :key="c_index">
                  <tr v-for="(s_item, s_index) in c_item.list" :key="s_index">
                    <td v-if="s_index === 0" :rowspan="c_item.list.length">
                      <span class="text">{{c_item.color}}</span>
                    </td>
                    <td v-if="s_index === 0" :rowspan="c_item.list.length">
                      <input class="ipt" type="text" v-model="c_item.colorCode" placeholder="请输入">
                    </td>
                    <td>
                      <span class="text">{{s_item.size}}</span>
                    </td>
                    <td>
                      <input class="ipt" type="text" v-model="s_item.millPrice"
                        placeholder="请输入"
                        @input="e => inputPrice(e, single_index, c_index, s_index, 'millPrice')" 
                        @blur="e => blurPrice(e, single_index, c_index, s_index, 'millPrice')"                      
                      >
                    </td>
                    <td>
                      <input class="ipt" type="text" v-model="s_item.wholesalePrice"
                        placeholder="请输入"
                        @input="e => inputPrice(e, single_index, c_index, s_index, 'wholesalePrice')" 
                        @blur="e => blurPrice(e, single_index, c_index, s_index, 'wholesalePrice')"                      
                      >
                    </td>
                    <td>
                      <input class="ipt" type="text" v-model="s_item.retailPrice"
                        placeholder="请输入"
                        @input="e => inputPrice(e, single_index, c_index, s_index, 'retailPrice')" 
                        @blur="e => blurPrice(e, single_index, c_index, s_index, 'retailPrice')"                      
                      >
                    </td>
                    <td>
                      <input class="ipt" type="text" v-model="s_item.tagPrice"
                        placeholder="请输入"
                        @input="e => inputPrice(e, single_index, c_index, s_index, 'tagPrice')" 
                        @blur="e => blurPrice(e, single_index, c_index, s_index, 'tagPrice')"                      
                      >
                    </td>
                    <td>
                      <input class="ipt" type="text" v-model="s_item.tailPrice"
                        placeholder="请输入"
                        @input="e => inputPrice(e, single_index, c_index, s_index, 'tailPrice')" 
                        @blur="e => blurPrice(e, single_index, c_index, s_index, 'tailPrice')"                      
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="single_item.skusChart.length == 0" 
                style="border: 1px solid #f0f0f0; border-top: none; overflow: hidden;"
              >
                <a-empty :image="simpleImage" />
              </div>
            </a-form-model-item>

            <a-form-model-item class="c-form-model-item" :label="cont.key" v-for="cont in single_item.tempProp && single_item.tempProp['2']" :key="cont.id">
              <custom-form-item :form-type="cont.type" :value.sync="cont.value" :range="cont.data" :unit="cont.unit"/>
            </a-form-model-item>
          </div>

          <!-- 分隔线 -->
          <div class="divider"></div>
          <h4 class="main-mod-tit">生产属性</h4>
          <div class="main-mod-wrap">
            <!-- manHour
                unitCost
                reworkRequired -->
            <a-form-model-item class="c-form-model-item" label="标准工时">
              <a-input style="width: 300px" v-model="single_item.manHour" placeholder="请输入标准工时"></a-input> 小时
            </a-form-model-item>
            <a-form-model-item class="c-form-model-item" label="单位成本">
              <a-input style="width: 300px" v-model="single_item.unitCost" placeholder="请输入单位成本"></a-input> 元
            </a-form-model-item>
            <a-form-model-item class="c-form-model-item" label="是否需要二次加工">
              <a-radio-group v-model="single_item.reworkRequired">
                <a-radio value="1">
                  是
                </a-radio>
                <a-radio value="2">
                  否
                </a-radio>
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item class="c-form-model-item" :label="cont.key" v-for="cont in single_item.tempProp && single_item.tempProp['3']" :key="cont.id">
              <custom-form-item :form-type="cont.type" :value.sync="cont.value" :range="cont.data" :unit="cont.unit"/>
            </a-form-model-item>
          </div>
          <!-- 新增的模板 -->
          <div v-for="item in single_item.extraTempProp" :key="item.id">
            <div class="divider"></div>
            <h4 class="main-mod-tit">{{item.name}}</h4>
            <div class="main-mod-wrap">
              <a-form-model-item class="c-form-model-item" :label="cont.key" v-for="cont in item.prop" :key="cont.id">
                <custom-form-item :form-type="cont.type" :value.sync="cont.value" :range="cont.data" :unit="cont.unit"/>
              </a-form-model-item>
            </div>
          </div>
        </div>
      </div>

    </a-form-model>

    <!-- 修改尺码 -->
    <a-modal v-model="editSizeVisible" title="修改尺码" :width="300" centered
      @ok="editSizeOk"
    >
      <a-input type="input" v-model="currentSizeName" placeholder="请输入尺码" />
    </a-modal>

    <!-- 尺码排序 -->
    <a-modal v-model="sizeSortVisible" title="尺码排序" centered @ok="sortSizeOk">
      <p style="color: #999">您可以直接通过拖拽调节顺序</p>
      <draggable
        class="drag-list-group"
        tag="div"
        v-model="newSortList"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <div
            class="drag-size-item"
            v-for="element in newSortList"
            :key="element.id"
          >
            {{ element.name }}
          </div>
        </transition-group>
      </draggable>
      <a-button type="link" style="margin-top: 8px; padding: 0" @click="sortRest">重置顺序</a-button>
    </a-modal>

    <!-- 批量填充价格 -->
    <a-modal v-model="batchFillVisible" title="批量填充"  centered
      @ok="batchFillHandle"
    >
      <a-form-model :model="batchFillForm" :label-col="{span: 8}" :wrapper-col="{span: 12}">
        <a-form-model-item label="出厂价格">
          <a-input v-model="batchFillForm.millPrice" placeholder="请输入出厂价格"
            style="width: 200px"
            @input="e => inputBatchPrice(e, 'millPrice')" 
            @blur="e => blurBatchPrice(e, 'millPrice')"
          /> 元
        </a-form-model-item>
        <a-form-model-item label="批发价格">
          <a-input v-model="batchFillForm.wholesalePrice" placeholder="请输入批发价格" 
            style="width: 200px"
            @input="e => inputBatchPrice(e, 'wholesalePrice')" 
            @blur="e => blurBatchPrice(e, 'wholesalePrice')"
          /> 元
        </a-form-model-item>
        <a-form-model-item label="零售价格">
          <a-input v-model="batchFillForm.retailPrice" placeholder="请输入零售价格" 
            style="width: 200px"
            @input="e => inputBatchPrice(e, 'retailPrice')" 
            @blur="e => blurBatchPrice(e, 'retailPrice')"
          /> 元
        </a-form-model-item>
        <a-form-model-item label="吊牌价格">
          <a-input v-model="batchFillForm.tagPrice" placeholder="请输入吊牌价格" 
            style="width: 200px"  
            @input="e => inputBatchPrice(e, 'tagPrice')" 
            @blur="e => blurBatchPrice(e, 'tagPrice')"
          /> 元
        </a-form-model-item>
        <a-form-model-item label="尾货价格">
          <a-input v-model="batchFillForm.tailPrice" placeholder="请输入尾货价格"
            style="width: 200px" 
            @input="e => inputBatchPrice(e, 'tailPrice')" 
            @blur="e => blurBatchPrice(e, 'tailPrice')"
          /> 元
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 查看大图 -->
    <a-modal v-model="previewVisible" :width="800" title="查看大图"  centered :footer="null">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <!-- 颜色选择框 -->
    <div class="color-select-pop"
      v-clickoutside="hideColorSelectPop"
      v-show="colorSelectPop.allShow || colorSelectPop.guessShow"
      :style="{
        left: colorSelectPop.posLeft + 'px',
        top: colorSelectPop.posTop + 'px'
      }"
    >
      <!-- 展示所有颜色 -->
      <div class="color-box" v-show="colorSelectPop.allShow">
        <div class="inner">
          <span class="item"
            v-for="(item,index) in colorLibrary"
            :key="index"
            :style="{width: item.length > 5 ? '160px': '80px'}"
            @click="selectColorHanle(item)"
          >{{item}}
          </span>
        </div>
      </div>
      <!-- 联想展示部分颜色 -->
      <div class="color-dropdown" v-show="colorSelectPop.guessShow && colorGuessList.length > 0">
        <ul class="inner">
          <li class="item" v-for="(item, index) in colorGuessList" :key="index"
            @click="selectColorHanle(item)"
          >{{item}}</li>
        </ul>
      </div>
    </div>

    <!-- 商品引用 -->
    <a-modal v-model="quoteGoodsVisible" title="商品引用" :width="800" centered :footer="null">
      <a-input-search v-model="quoteSearchKey" placeholder="请输入商品名称" style="width: 208px" @search="onSearch" />
      <!-- <a-checkbox @change="onQuoteChange" style="margin-left: 24px;">
        勾选该选项后，被引用的商品数据被修改后将同步原商品
      </a-checkbox> -->
      <!-- table -->
      <div class="quote-list">
        <div class="q-header">
          <span class="td" style="width: 50%">商品名称</span>
          <span class="td" style="width: 40%">商品类目</span>
        </div>
        <a-empty v-if="quoteGoodsList.length == 0" :image="simpleImage" description="暂无数据" />
        <div class="q-body" v-else>
          <div class="suit" v-for="item in quoteGoodsList" :key="item.id">
            <div class="tit">
              <span style="width: 51.5%;">{{item.name}}</span>
              <span style="width: 40%">{{item.categoryName}}</span>
            </div>
            <div class="single" v-for="ele in item.subList" :key="ele.id">
              <div class="td" style="width: 90%">
                <span class="img-box">
                  <img v-if="ele.imageUrl" :src="ele.imageUrl" >
                </span>
                <div>{{ele.name}}</div>
              </div>
              <div class="td" style="width: 10%">
                <a-button @click="selectQuote(ele.id)">选择</a-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- pagination -->
      <div style="text-align: right; padding: 24px 0 0;">
        <a-pagination
          v-show="quotePage.total>0"
          show-quick-jumper 
          :default-current="1" 
          :show-total="total => `共${quotePage.total}条`" 
          :total="quotePage.total" 
          @change="onPageChange" 
        />
      </div>
    </a-modal>

    <!-- 完善信息 -->
    <a-modal v-model="optimizeVisible" :width="640" title="完善信息"  centered
      @ok="optimizeOk"
    >
     <h4 style="text-align: center; font-size: 16px; padding: 16px 0 24px;">您所创建的商品为套装商品，请完善该套装信息。</h4>
     <a-form-model :model="optimizeForm" :label-col="{span: 8}" :wrapper-col="{span: 9}">
        <a-form-model-item label="商品名称">
          <a-input v-model="optimizeForm.name" placeholder="请输入商品名称" /> 
        </a-form-model-item>
        <a-form-model-item label="商品型号">
          <a-input v-model="optimizeForm.code" placeholder="请输入商品型号" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
import Editor from '@/components/Editor.vue'
import CustomFormItem from '@/components/CustomFormItem.vue'
import { nanoid } from 'nanoid'
import draggable from 'vuedraggable'
import { Empty } from 'ant-design-vue'
import { debounce, getOffsetDistance, deepClone, buildThumborUrl } from '@/utils/tools'
import { approvedCompanyList } from '@/api/company'
import { listTag, listTree } from '@/api/tag'
import { 
  dataCategoryList,
  dataItemList,
  propertyList,
  templateList,
} from '@/api/system'
import { updateGoods, goodsAndsingleList, singleDetail, goodsDetail } from '@/api/goods'
import { uploadFileApi } from '@/api/upload'
// import { buildThumborUrl } from '@/utils/tools'
import { mapGetters } from 'vuex'

// 判断数组里是否有重复元素
function isRepeat(arr) {
  var hash = {};
  for(var i in arr) {
    if(hash[arr[i]]) {
      return true
    }
    // 不存在该元素，则赋值为true
    hash[arr[i]] = true
  }
  return false
}

export default {
  name: 'static-data-goods-list-edit',
  components: {
    FileUpload,
    Editor,
    draggable,
    CustomFormItem
  },
  props: ['id', 'addSingleFlag', 'saveGoodsFlag'],
  watch: {
    addSingleFlag: function(flag) {
      console.log('addSingle'+flag)
      this.addSingle()
    },
    saveGoodsFlag: function(flag) {
      console.log('saveGoodsFlag'+flag)
      this.saveHandle()
    }
  },
  data() {
    // 自定义表单校验 - 商品主图
    let validateMainPic = (rule, value, callback) => {
      console.log('validateMainPic', rule, value)
      if (this.addForm.fileList.length == 0) {
        callback(new Error('请上传商品主图'))
      } else if (this.addForm.fileList.length > 9) {
        callback(new Error("不能超过9张图片"))
      } else {
        callback()
      }
    }

    let validateColorArr = (rule, value, callback) => {
      console.log('validateColorArr', rule, value)
      let r = value.filter(item => item.color.trim() != '')
      let colors = r.map(item => item.color)
      if (r.length == 0) {
        callback(new Error('请填写颜色'))
      } else if (isRepeat(colors)) {
        callback(new Error('颜色重复了'))
      } else {
        callback()
      }
    }

    let validatesizeArrSelect = (rule,value,callback) => {
      console.log(rule,value)
      if (value.length == 0) {
        callback('请选择尺码')
      } else {
        callback()
      }
    }
    return {
      // 自定义表单校验函数
      validateMainPic,
      validateColorArr,
      validatesizeArrSelect,

      // 空状态图
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,

      uploadFileApi,
      uploadData: {
        bucketName: 'yijiajia-item-image'
      },

      richContent: '',

      // 当前单品序号
      currentSingleIndex: 0,

      // 汉语数字对照
      chineseNumberConf: {
        '1': '一', '2': '二', '3': '三', '4': '四', '5': '五',
        '6': '六', '7': '七', '8': '八', '9': '九', '10': '十'
      },
      // 系统尺码数据
      sizeConfList: [], 
      // 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'

      // 修改尺码
      editSizeVisible: false,
      oldSizeName: '',
      currentSizeName: '',
      currentSizeObj: '',

      // 尺码排序
      sizeSortVisible: false,
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      oldSortList: [],
      newSortList: [],

      // 颜色库
      colorLibrary: [
        // '乳白', 
        // '浅灰', 
        // '桔红色', 
      ],
      colorGuessList: [],

      // 颜色选择框
      colorSelectPop: {
        // 聚焦
        focus: false, 

        // 当前操作的序号
        index: 0,

        // 是否显示
        allShow: false,
        guessShow: false,

        // 位置
        posLeft: 0,
        posTop: 0,
      },
      
      // 类目数据tree
      treeData: [],

      // 模板字典
      tempDic: {
        "商品属性": "1",
        "销售属性": "2",
        "生产属性": "3"
      },
      // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
      tempProp: {
        "1": [],
        "2": [],
        "3": [],
      },
      // 自定义的模板及属性数组
      extraTempProp: [
        // {
        //   temp: {
        //     name: "",
        //     prop: []
        //   }
        // }
      ], 




      // 新增商品表单
      addForm: {
        category: [],
        editCategory: false,
        company: undefined,
        brand: undefined,

        fileList: [],

        // 单品列表
        singleList: [
          {
            id: nanoid(),
            
            // 单品名称
            singleName: '',

            // 商品型号
            singleCode: '',

            // 富文本
            richContent: '',
            richDataUpdated: '',

            // 颜色表
            colorArr: [{
              color: '',
              url: '',
              resUrl: '',
              id: nanoid()
            }],

            // 尺码
            sizeArr: [],
            sizeArrSelect: [],
            addSizeVal: '',

            // 尺码表 - 销售规格 
            skusChart: [],

            // 标准工时
            manHour: '',
            // 单位成本
            unitCost: '',
            // 是否需要二次加工
            reworkRequired: '',

            // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
            tempProp: {
              "1": [],
              "2": [],
              "3": [],
            },
            // 自定义的模板及属性数组
            extraTempProp: [
              // {
              //   temp: {
              //     name: "",
              //     prop: []
              //   }
              // }
            ], 
            property: {

            }

          }
        ]

      },
      rules: {

      },
      // 图片预览
      previewVisible: false,
      previewImage: '',

      // 批量填充
      batchFillVisible: false,
      batchFillForm: {
        millPrice: '',
        wholesalePrice: '',
        retailPrice: '',
        tagPrice: '',
        tailPrice: ''
      },

      fileList: [
        // {
        //   uid: '-1',
        //   name: 'xxx.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //   thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //   resUrl: ''
        // },
        // {
        //   uid: '-2',
        //   name: 'yyy.png',
        //   status: 'done',
        //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //   thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
      ],

      // 颜色表
      colorArr: [{
        color: '',
        url: '',
        resUrl: '',
        id: nanoid()
      }],

      // 颜色尺码表
      skusChart: [
        {
          id: nanoid(),
          color: '红色',
          colorCode: 'Yh900034',
          thumbUrl: '',
          url: '',
          list: [
            {
              id: nanoid(),
              size: 'M',
              millPrice: '',
              wholesalePrice: '',
              retailPrice: '',
              tagPrice: '',
              tailPrice: ''
            },
            {
              id: nanoid(),
              size: 'L',
              millPrice: '',
              wholesalePrice: '',
              retailPrice: '',
              tagPrice: '',
              tailPrice: ''
            }
          ]
        },
        {
          id: nanoid(),
          color: '绿色',
          colorCode: 'Yh900034',
          thumbUrl: '',
          url: '',
          list: [
            {
              id: nanoid(),
              size: 'M',
              millPrice: '',
              wholesalePrice: '',
              retailPrice: '',
              tagPrice: '',
              tailPrice: ''
            },
            {
              id: nanoid(),
              size: 'L',
              millPrice: '',
              wholesalePrice: '',
              retailPrice: '',
              tagPrice: '',
              tailPrice: ''
            }
          ]
        }
      ],

      // 商品引用
      quoteGoodsVisible: false,
      quoteGoodsList: [],
      quoteSearchKey: '',
      quotePage: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: total => `共${total}条`,
        showSizeChanger: true,
        showQuickJumper: true
      },

      // 完善信息
      optimizeVisible: false,
      optimizeForm: {
        name: '',
        code: '',
      },

      // 企业数据
      companyData: [],
      // 品牌数据
      brandData: [],

      detailData: {}
      

    }
  },
  computed: {
    ...mapGetters(['adminId'])
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    // 初始化
    init() {
      this.$showLoading()
      Promise.all([
        this.getsizeList(), 
        this.getColorLibrary(), 
        this.getGoodsTag(),
        this.getTemplate()
      ])
        .then(arr => {
          console.log(arr)
          this.$hideLoading()
          this.initSize()
          this.goodsDetail()
        }).catch((error) => {
          this.$hideLoading()
          console.log(error)
        })
    },
    goodsDetail() {
      this.$showLoading()
      goodsDetail({itemId: this.id}).then(res => {
        console.log(res);
        this.$hideLoading()
        if (res.code == 200) {
          this.detailData = res.data
          this.addForm.company = res.data.companyName
          this.addForm.brand = res.data.brand
          if (Array.isArray(res.data.images)) {
            this.addForm.fileList = res.data.images.map((item, index) => {
              return {
                uid: (index+1) + '',
                name: item,
                status: 'done',
                url: '',
                thumbUrl: buildThumborUrl(item),
                resUrl: item
              }
            })
          }
          this.optimizeForm.name = res.data.name
          this.optimizeForm.code = res.data.model
          this.addForm.singleList =  res.data.subItemList.map(() => {
            return {
              id: nanoid(),
              // 商品型号
              singleCode: '',

              // 富文本
              richContent: '',
              richDataUpdated: '',

              // 颜色表
              colorArr: [{
                color: '',
                url: '',
                resUrl: '',
                id: nanoid()
              }],

              // 尺码
              sizeArr: this.sizeConfList.map(item => {
                return {
                  id: item.categoryId,
                  name: item.categoryName,
                  isDefault: true
                }
              }),
              sizeArrSelect: [],
              addSizeVal: '',

              // 尺码表 - 销售规格 
              skusChart: [],

              // 标准工时
              manHour: '',
              // 单位成本
              unitCost: '',
              // 是否需要二次加工
              reworkRequired: '',

              // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
              tempProp: deepClone(this.tempProp),
              // 自定义的模板及属性数组
              extraTempProp: deepClone(this.extraTempProp),
              property: {

              }
            }
          })
          res.data.subItemList.forEach((item, index) => {
            this.fillDataToForm(item, index)
          })
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err);
      })
    },
    // 初始化尺码
    initSize() {
      this.addForm.singleList.forEach(item => {
        item.sizeArr = this.sizeConfList.map(item => {
          return {
            id: item.categoryId,
            name: item.categoryName,
            isDefault: true
          }
        })
      })
    },

    // 获取配置颜色
    async getColorLibrary() {
      try {
        let res1 = await dataCategoryList({
          current: 1, size: 1, categoryTypes: '301'
        })
        let record = res1.data.records[0]
        let res2 = await dataItemList({parentId: record.categoryId})
        this.colorLibrary = res2.data.map(item => {
          return item.categoryName
        })
        return true
      } catch (error) {
        return error
      }
    },
    // 获取配置尺码
    async getsizeList() {
      try {
        let res1 = await dataCategoryList({
          current: 1, size: 1, categoryTypes: '302'
        })
        let record = res1.data.records[0]
        let res2 = await dataItemList({parentId: record.categoryId})
        this.sizeConfList = res2.data
        return true
      } catch (error) {
        return error
      }
    },

    // 输入所属企业
    fetchCompany(value) {
      approvedCompanyList({
        current: 1,
        size: 20,
        keyword: value,
      }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.companyData = res.data.records
        } else {
          this.$message.error(res.msg || '获取公司列表失败')
        }
      }).catch(err => {
        console.error(err);
      })
    },
    handleCompanySearch(value) {
      console.log('handleCompanySearch', value)
      debounce(this.fetchCompany(value))
    },
    handleCompanyChange(value) {
      console.log('handleCompanyChange', value)
      this.addForm.company = value
      debounce(this.fetchCompany(value))
    },

    // 输入品牌
    // fetchBrand(value) {
    //   brandList({keyword: value}).then(res => {
    //     console.log(res)
    //     if (res.code === 200) {
    //       this.brandData = res.data
    //     } else {
    //       this.$message.error(res.msg || '获取公司列表失败')
    //     }
    //   }).catch(err => {
    //     console.error(err);
    //   })
    // },
    // handleBrandSearch(value) {
    //   console.log('handleBrandSearch', value)
    //   debounce(this.fetchBrand(value))
    // },
    // handleBrandChange(value) {
    //   console.log('handleBrandChange', value)
    //   this.addForm.brand = value
    //   debounce(this.fetchBrand(value))
    // },

    // 获取商品类目标签
    async getGoodsTag() {
      try {
        let res1 = await listTag({parentId: 0})
        let goodsTag = res1.data.find(item => item.tagName == '商品标签')
        let res2 = await listTree({tagId: goodsTag.tagId})
        console.log(res2.data)
        let catogoryTree = res2.data[0].children.find(item => item.tagName == '商品类目').children
        this.treeData = catogoryTree
        return true
      } catch (error) {
        return error
      }
    },

    // 获取模板数据
    async getTemplate() {
      try {
        let resTem = await templateList({parentId: '', type: 2})
        console.log('getTemplate', resTem.data)
        let tempList = resTem.data
        let {tempProp, extraTempProp} = await this.getProp(tempList)
        this.tempProp = tempProp
        this.extraTempProp = extraTempProp
        this.addForm.singleList = this.addForm.singleList.map(s => {
          return {
            ...s,
            tempProp: deepClone(tempProp),
            extraTempProp: deepClone(extraTempProp)
          }
        })
        console.log('getTemplate', this.tempProp, this.extraTempProp,this.addForm)
        return true
      } catch (error) {
        return error
      }
    },
    // 获取属性列表
    async getProp(tempList) {
      try {
        let tempProp = {
          "1": [],
          "2": [],
          "3": [],
        }
        let extraTempProp = []
        for (let i = 0; i < tempList.length; i++) {
          let temp = tempList[i]
          let resProp = await propertyList({parentId: temp.id})
          if (resProp.code == 200 && resProp.data && resProp.data.length>0) {
            let propArray = resProp.data.map(item => {
              let propertys = JSON.parse(item.propertyContent)
              let dataRange = '', value = ''
              if (propertys.controlType == 1) {
                dataRange = []
                value = propertys.presetValue || ''
              } else {
                dataRange = propertys.range
              }
              if (propertys.controlType == 2) {
                value = ''
              }
              if (propertys.controlType == 3) {
                value = ''
              }
              if (propertys.controlType == 4) {
                value = []
              }
              if (propertys.controlType == 5) {
                value = [...dataRange]
              }
              return {
                id: item.id,
                key: item.name,
                value,
                unit: propertys.unit,
                type: propertys.controlType,
                data: dataRange
              }
            })
            if (temp.name === '商品属性') {
              tempProp['1'] = propArray
            } else if (temp.name === '销售属性') {
              tempProp['2'] = propArray
            } else if (temp.name === '生产属性') {
              tempProp['3'] = propArray
            } else {
              extraTempProp.push({
                id: temp.id,
                name: temp.name,
                prop: propArray
              })
            }
          }
        }
        return {tempProp, extraTempProp}
      } catch (error) {
        return error
      }
    },
    
    // 新增单品
    addSingle() {
      this.addForm.singleList.push({
        id: nanoid(),
        // 商品型号
        singleCode: '',

        // 富文本
        richContent: '',
        richDataUpdated: '',

        // 颜色表
        colorArr: [{
          color: '',
          url: '',
          resUrl: '',
          id: nanoid()
        }],

        // 尺码
        sizeArr: this.sizeConfList.map(item => {
          return {
            id: item.categoryId,
            name: item.categoryName,
            isDefault: true
          }
        }),
        sizeArrSelect: [],
        addSizeVal: '',

        // 尺码表 - 销售规格 
        skusChart: [],

        // 标准工时
        manHour: '',
        // 单位成本
        unitCost: '',
        // 是否需要二次加工
        reworkRequired: '',

        // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
        tempProp: deepClone(this.tempProp),
        // 自定义的模板及属性数组
        extraTempProp: deepClone(this.extraTempProp),
        property: {

        }
      })
      this.$message.success('添加单品成功')
    },
    // 删除单品
    delSingle(index) {
      this.$confirm({
        title: `确认删除该单品吗？`,
        centered: true,
        onOk: () => {
          console.log("OK")
					this.addForm.singleList.splice(index, 1)
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },

    // 图片上传
    // 上传文件之前
    customBeforeUpload(file) {
      // if (this.addForm.fileList.length + fileList.length > 9) {
      //   this.$message.error('最多上传9张图片')
      //   return false
      // }
      this.$showLoading()
    },
    // 上传图片
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      if (fileList.length > 9) {
        this.$message.error('最多上传9张图片')
        this.$hideLoading()
      }
      fileList = fileList.slice(0, 9);

      this.addForm.fileList = fileList;
      console.log('handleUploadChange: uploading', info.file, info.fileList);
      if (info.file.status !== 'uploading') {
        console.log('handleUploadChange', info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        let response = info.file.response
        if (response.code == 200) {
          this.$hideLoading()
          this.$message.success('上传成功')
        } else {
          this.$message.error(response.msg || '上传失败')
        }
      } else if (info.file.status === 'error') {
        this.$hideLoading()
        this.$message.error('上传失败')
      }
    },
    // 图片预览
    handlePreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },

    handleColorImgChange(info, item, single_index) {
      let single = this.addForm.singleList[single_index]
      this.$showLoading()
      if (info.file.status !== 'uploading') {
        this.$hideLoading()
        console.log(info.file, info.fileList, item);
      }
      if (info.file.status === 'done') {
        if (info.file.response.code == 200 && info.file.response.data[0]) {
          item.url = URL.createObjectURL(info.file.originFileObj)
          item.resUrl = info.file.response.data[0]
          single.colorArr = [...single.colorArr]
          this.$message.success('上传成功');
        } else {
          this.$message.error(`上传失败`);
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`上传失败`);
      }
    },

    // 输入颜色值
    colorItemInput(e, item, index, single_index) {
      // console.log('colorItemInput', e)
      this.currentSingleIndex = single_index
      this.colorSelectPop.index = index
      let single = this.addForm.singleList[single_index]
      if (e.target.value.length > 0) {
        let value = e.target.value.trim()
        this.colorGuessList = this.colorLibrary.filter(item => item.indexOf(value) >= 0)
        this.colorSelectPop.allShow = false
        this.colorSelectPop.guessShow = true
      } else {
        this.colorSelectPop.allShow = true
        this.colorSelectPop.guessShow = false
      }
      if (index == single.colorArr.length - 1) {
        if (item.color.length > 0) {
          single.colorArr.push({
            color: "",
            url: "",
            resUrl: '',
            id: nanoid()
          });
        }
      }
      this.$refs.addForm.validateField('singleList.' + single_index + '.colorArr')
      debounce(this.checkSameColor(item, index))
    },
    colorItemFocus(e, item, index, single_index) {
      this.colorSelectPop.focus = true
      this.currentSingleIndex = single_index
      this.colorSelectPop.index = index
      // console.dir('colorItemFocus', e.target)
      let offsetLeft = getOffsetDistance(e.target)
      let offsetTop = getOffsetDistance(e.target, 'top')
      let offsetHeight = e.target.offsetHeight

      // console.log('offsetLeft, Top', offsetLeft, offsetTop, offsetHeight)
      let value = e.target.value.trim()
      if (value.length > 0) {
        this.colorGuessList = this.colorLibrary.filter(item => item.indexOf(value) >= 0)
        this.colorSelectPop.allShow = false
        this.colorSelectPop.guessShow = true
      } else {
        this.colorSelectPop.allShow = true
        this.colorSelectPop.guessShow = false
      }
      this.colorSelectPop.posLeft = offsetLeft
      this.colorSelectPop.posTop = offsetTop + offsetHeight + 2
    },
    colorItemBlur() {
      this.colorSelectPop.focus = false
    },
    hideColorSelectPop() {
      if (!this.colorSelectPop.focus) {
        this.colorSelectPop.allShow = false
        this.colorSelectPop.guessShow = false
      }
    },
    selectColorHanle(color) {
      console.log('selectColorHanle', color)
      let i1 = this.currentSingleIndex
      let i2 = this.colorSelectPop.index
      let single = this.addForm.singleList[i1]
      console.log(single)
      single.colorArr[i2].color = color
      this.colorSelectPop.allShow = false
      this.colorSelectPop.guessShow = false
      if (i2 == single.colorArr.length - 1) {
        if (single.colorArr[i2].color.length > 0) {
          single.colorArr.push({
            color: "",
            url: "",
            resUrl: '',
            id: nanoid()
          });
        }
      }
      this.$refs.addForm.validateField('singleList.' + this.currentSingleIndex + '.colorArr')
      this.checkSameColor(single.colorArr[i2], this.colorSelectPop.index)
    },
    // 判断颜色值是否重复
    checkSameColor(item, index) {
      let single = this.addForm.singleList[this.currentSingleIndex]
      if (
        item.color != "" &&
        single.colorArr.some((ele, i) => i != index && ele.color == item.color)
      ) {
        item.err = true
      } else {
        single.colorArr.forEach(ele => {
          if (ele.err) ele.err = false
        })
        this.createSkusChart("color")
      }
    },
    // 删除颜色
    colorItemDel(item, index, single_index) {
      this.currentSingleIndex = single_index
      this.addForm.singleList[single_index].colorArr.splice(index, 1)
      this.addForm.singleList = [...this.addForm.singleList]
      this.createSkusChart("color")
      this.$refs.addForm.validateField('singleList.' + single_index + '.colorArr')
    },

    // 勾选尺码
    sizeChangeHandle(single_index) {
      this.currentSingleIndex = single_index
      this.addForm.singleList = [...this.addForm.singleList]
      this.createSkusChart("size")
      this.$refs.addForm.validateField('singleList.' + single_index + '.sizeArrSelect')
    },
    // 添加尺码
    addSizeHandle(value, single_item, single_index) {
      this.currentSingleIndex = single_index
      console.log('addSizeHandle', value)
      value = value.trim()
      if (value === '') return
      if (single_item.sizeArr.find(s => s.name == value)) return this.$message.error('该尺码已存在')
      single_item.sizeArr.push({
        id: nanoid(),
        name: value,
        isDefault: false
      })
      single_item.sizeArrSelect.push(value)
      single_item.addSizeVal = ''
      this.createSkusChart('size')
      this.$refs.addForm.validateField('singleList.' + single_index + '.sizeArrSelect')
    },
    // 编辑尺码
    editSizeHandle(size_item, single_index) {
      this.editSizeVisible = true
      this.currentSizeObj = size_item
      this.oldSizeName = size_item.name
      this.currentSizeName = size_item.name
      this.currentSingleIndex = single_index
    },
    // 确认编辑
    editSizeOk() {
      let value = this.currentSizeName.trim()
      if (value === '') return this.$message.error('请输入尺码')
      let sizeArr = this.addForm.singleList[this.currentSingleIndex].sizeArr
      if (value != this.oldSizeName && sizeArr.find(s => s.name == value)) return this.$message.error('该尺码已存在')

      this.editSizeVisible = false
      let sizeArrSelect = this.addForm.singleList[this.currentSingleIndex].sizeArrSelect
      let i1 = sizeArr.findIndex(s => s.name == this.currentSizeObj.name)
      let i2 = sizeArrSelect.findIndex(s => s == this.currentSizeObj.name)
      if (i1 >= 0) this.addForm.singleList[this.currentSingleIndex].sizeArr[i1].name = value
      if (i2 >= 0) this.addForm.singleList[this.currentSingleIndex].sizeArrSelect[i2] = value
      this.createSkusChart('size')
    },
    // 删除尺码 
    deleteSizeHandle(size_item, single_item, single_index) {
      this.currentSingleIndex = single_index
      single_item.sizeArr = single_item.sizeArr.filter(s => s.name !== size_item.name)
      single_item.sizeArrSelect = single_item.sizeArrSelect.filter(s => s !== size_item.name)
      this.addForm.singleList = [...this.addForm.singleList]
      this.createSkusChart('size')
      this.$refs.addForm.validateField('singleList.' + single_index + '.sizeArrSelect')
    },

    // 尺码排序
    openSortProp(single_index) {
      this.currentSingleIndex = single_index
      let sizeArrSelect = this.addForm.singleList[single_index].sizeArrSelect
      if (sizeArrSelect.length < 2) {return this.$message.error('选择的尺码数至少2个才可以排序')}
      this.sizeSortVisible = true
      let sizeArr = this.addForm.singleList[single_index].sizeArr
      let sortList = sizeArr.filter(s => sizeArrSelect.indexOf(s.name) >= 0 )
      this.oldSortList = [...sortList]
      this.newSortList = [...sortList]
    },
    sortRest() {
      this.newSortList = [...this.oldSortList]
    },
    sortSizeOk() {
      console.log('newSortList', this.newSortList)
      this.sizeSortVisible = false
      let i = this.currentSingleIndex
      let sizeArr = this.addForm.singleList[i].sizeArr
      let sizeArrSelect = this.addForm.singleList[i].sizeArrSelect
      let restArr =  sizeArr.filter(s => sizeArrSelect.indexOf(s.name) == -1)
      this.addForm.singleList[i].sizeArr = [...this.newSortList, ...restArr]

      this.createSkusChart('size')
    },

    // 控制价格
    inputPrice(e, single_index, c_index, s_index, key) {
      let single = this.addForm.singleList[single_index]
      let reg = /^\d+(\.)?(\d{1,2})?/
      let match = e.target.value && e.target.value.match(reg) || ''
      // console.log(match)
      single.skusChart[c_index]['list'][s_index][key] = match && match[0] || ''
    },
    blurPrice(e, single_index, c_index, s_index, key) {
      let single = this.addForm.singleList[single_index]
      if (e.target.value !== '') {
        single.skusChart[c_index]['list'][s_index][key] = parseFloat(e.target.value) + ''
      }
    },

    // 控制批量填充表单价格
    inputBatchPrice(e, key) {
      let reg = /^\d+(\.)?(\d{1,2})?/
      let match = e.target.value && e.target.value.match(reg) || ''
      // console.log(e.target.value, match)
      this.batchFillForm[key] = match && match[0] || ''
    },
    blurBatchPrice(e, key) {
      if (e.target.value !== '') {
        this.batchFillForm[key] = parseFloat(e.target.value) + ''
      }
    },
    openBatchFill(single_index) {
      this.currentSingleIndex = single_index
      this.batchFillVisible = true
    },
    batchFillHandle() {
      console.log(this.batchFillForm)
      let single = this.addForm.singleList[this.currentSingleIndex]
      this.batchFillVisible = false
      single.skusChart.forEach(sku => {
        sku.list.forEach(sizeItem => {
          if (this.batchFillForm.millPrice !== '') sizeItem.millPrice = this.batchFillForm.millPrice
          if (this.batchFillForm.wholesalePrice !== '') sizeItem.wholesalePrice = this.batchFillForm.wholesalePrice
          if (this.batchFillForm.retailPrice !== '') sizeItem.retailPrice = this.batchFillForm.retailPrice
          if (this.batchFillForm.tagPrice !== '') sizeItem.tagPrice = this.batchFillForm.tagPrice
          if (this.batchFillForm.tailPrice !== '') sizeItem.tailPrice = this.batchFillForm.tailPrice
        })
      })
      this.batchFillForm = {
        millPrice: '',
        wholesalePrice: '',
        retailPrice: '',
        tagPrice: '',
        tailPrice: ''
      }
    },

    // 生成销售规格表格
    createSkusChart(type) {
      let single = this.addForm.singleList[this.currentSingleIndex]
      console.log(single)

      let colorList = [], sizeList = []

      colorList = single.colorArr.filter(c => c.color.trim() !== '')
      if (colorList.length === 0) {
        single.skusChart = []
        return
      }

      sizeList = single.sizeArr.filter(s => single.sizeArrSelect.indexOf(s.name) >= 0)
      if (sizeList.length === 0) {
        single.skusChart = []
        return
      }

      // 已存在生成的尺码表
      if (single.skusChart.length !== 0) {
    
        if (type === 'color') {
          let copySkusChart = [...single.skusChart]
          single.skusChart = colorList.map(colorItem => {
            let colorObj = copySkusChart.find(sku => sku.id == colorItem.id)
            if (colorObj) {
              return {
                id: colorItem.id,
                color: colorItem.color,
                url: colorItem.resUrl || '',
                colorCode: colorObj.colorCode,
                list: colorObj.list
              }
            } else {
              return {
                id: colorItem.id,
                color: colorItem.color,
                url: colorItem.resUrl || '',
                colorCode: single.singleCode || '',
                list: sizeList.map(sizeItem => {
                  return {
                    id: sizeItem.id,
                    size: sizeItem.name,
                    millPrice: '',
                    wholesalePrice: '',
                    retailPrice: '',
                    tagPrice: '',
                    tailPrice: ''
                  }
                })
              }
            }
          })
          
        }

        if (type === 'size') {
          single.skusChart.forEach(sku => {
            let copyList = [...sku.list]
            sku.list = sizeList.map((sizeItem) => {
              let sizeObj = copyList.find(s => s.id == sizeItem.id)
              if (sizeObj) {
                return {
                  id: sizeItem.id,
                  size: sizeItem.name,
                  millPrice: sizeObj.millPrice,
                  wholesalePrice: sizeObj.wholesalePrice,
                  retailPrice: sizeObj.retailPrice,
                  tagPrice: sizeObj.tagPrice,
                  tailPrice: sizeObj.tailPrice,
                }
              } else {
                return {
                  id: sizeItem.id,
                  size: sizeItem.name,
                  millPrice: '',
                  wholesalePrice: '',
                  retailPrice: '',
                  tagPrice: '',
                  tailPrice: '',
                }
              }
            })
          })
        }

      }
      
      // 尺码表为空时
      if (single.skusChart.length === 0) {
        single.skusChart = colorList.map(colorItem => {
          return {
            id: colorItem.id,
            color: colorItem.color,
            url: colorItem.resUrl || '',
            colorCode: single.singleCode,
            list: sizeList.map(sizeItem => {
              return {
                id: sizeItem.id,
                size: sizeItem.name,
                millPrice: '',
                wholesalePrice: '',
                retailPrice: '',
                tagPrice: '',
                tailPrice: ''
              }
            })
          }
        })
      }
      
      /*
          id: nanoid(),
          color: '红色',
          colorCode: 'Yh900034',
          // thumbUrl: '',
          url: '',
          list: [
            {
              id: nanoid(),
              size: 'M',
              millPrice: '',
              wholesalePrice: '',
              retailPrice: '',
              tagPrice: '',
              tailPrice: ''
            },
      */
      
    },


    // 打开商品引用弹框
    openQuoteGoodsBox(single_index) {
      this.quoteGoodsVisible = true
      this.currentSingleIndex = single_index
    },
    // 获取引用列表
    goodsAndsingleList() {
      this.$showLoading()
      goodsAndsingleList({
        current: this.quotePage.current,
        size: this.quotePage.pageSize,
        name: this.quoteSearchKey
      }).then(res => {
        this.$hideLoading()
        if (res.code === 200) {
          console.log(res) 
          this.quotePage.total = res.data.total
          this.quoteGoodsList = res.data.records.map(item => {
            return {
              ...item,
              subList: item.subItemQuoteVOList.map(ele => {
                let images = ele.images.split(',')
                let imageUrl = ''
                if (images && images.length > 0) {
                  imageUrl = buildThumborUrl(images[0]) 
                }
                return {
                  ...ele,
                  imageUrl
                }
              })
            }
          })
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      })
    },
    // 选择引用
    selectQuote(id) {
      this.$confirm({
        title: `确认选择引用该单品吗？`,
        centered: true,
        onOk: () => {
          console.log("OK")
          this.singleDetail(id)
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },
    // 获取单品详情
    singleDetail(id) {
      this.$showLoading()
      singleDetail({subItemId: id}).then(res => {
        this.$hideLoading()
        console.log(res)
        console.log(this.currentSingleIndex)
        if (res.code == 200) {
          this.fillDataToForm(res.data)
          this.quoteGoodsVisible = false
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err);
      })
    },

    // 引用数据填充
    fillDataToForm(data, sIndex) {
      let curIndex
      if (typeof sIndex === 'number') {
        curIndex = sIndex
      } else {
        curIndex = this.currentSingleIndex
      }
      let curSingle = this.addForm.singleList[curIndex]

      let se = {}
      se.singleName = data.name
      se.singleCode = data.model
      se.richContent = data.expandInfo
      se.richDataUpdated = Math.random() + ''

      let subItemColorList = data.subItemColorList.sort((a,b) => a.sequence - b.sequence)
      let subItemSizeList = data.subItemSizeList.sort((a,b) => a.sequence - b.sequence)
      // 颜色
      se.colorArr = subItemColorList.map(item => {
        return {
          color: item.name,
          url: item.image ? buildThumborUrl(item.image) : '',
          resUrl: item.image || '',
          id: nanoid()
        }
      })
      se.colorArr.push({
        color: '',
        url: '',
        resUrl: '',
        id: nanoid()
      })
      // 尺码
      let sizeArr= subItemSizeList.map(item => ({id: nanoid(), name: item.name, isDefault: false}))
      for(let i = 0; i< this.sizeConfList.length; i++) {
        let sizeConf = this.sizeConfList[i]
        let s_index = sizeArr.findIndex(item => item.name == sizeConf.categoryName)
        if (s_index>=0) {
          sizeArr[s_index].isDefault = true
        } else {
          sizeArr.push({id: nanoid(), name: sizeConf.categoryName, isDefault: true})
        }
      }
      se.sizeArr = sizeArr
      se.sizeArrSelect = subItemSizeList.map(item => item.name)
      se.addSizeVal = ''

      // 尺码表 - 销售规格
      let subItemPriceList = data.subItemPriceList
      let sizeLen = subItemSizeList.length
      let skusChart = subItemColorList.map((color, i) => {
        let list = subItemSizeList.map((size, j) => {
          let pIndex = (sizeLen * i) + j
          return {
            id: nanoid(),
            size: size.name,
            millPrice: subItemPriceList[pIndex].factoryPrice,
            wholesalePrice: subItemPriceList[pIndex].wholesalePrice,
            retailPrice: subItemPriceList[pIndex].retailPrice,
            tagPrice: subItemPriceList[pIndex].tagPrice,
            tailPrice: subItemPriceList[pIndex].tailPrice,
          }
        })
        return {
          id: nanoid(),
          color: color.name,
          colorCode: color.model,
          thumbUrl: '',
          url: color.image,
          list
        }
      })
      se.skusChart = skusChart

      let property
      try {
        property=JSON.parse(data.property)
      } catch (error) {
        property = {}
      }
      // 标准工时
      se.manHour = property.manHour
      // 单位成本
      se.unitCost = property.unitCost,
      // 是否需要二次加工
      se.reworkRequired = property.reworkRequired

      // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
      let tempProp1_obj = {}
      let tempProp2_obj = {}
      let tempProp3_obj = {}
      property.tempProp['1'].forEach(item => {
        tempProp1_obj[item.id] = item
      })
      property.tempProp['2'].forEach(item => {
        tempProp2_obj[item.id] = item
      })
      property.tempProp['3'].forEach(item => {
        tempProp3_obj[item.id] = item
      })
      let tempProp = curSingle.tempProp
      console.log('tempProp',tempProp)
      tempProp['1'].forEach(item => {
        if (tempProp1_obj[item.id].value) {
          item.value = tempProp1_obj[item.id].value
        }
      })
      tempProp['2'].forEach(item => {
        if (tempProp2_obj[item.id].value) {
          item.value = tempProp2_obj[item.id].value
        }
      })
      tempProp['3'].forEach(item => {
        if (tempProp3_obj[item.id].value) {
          item.value = tempProp3_obj[item.id].value
        }
      })
      se.tempProp = tempProp
      
      // 自定义的模板及属性数组

      let extraTempProp_obj = {}
      property.extraTempProp.forEach(item => {
        item.prop.forEach(ele => {
          extraTempProp_obj[ele.id] = ele
        })
      })
      let extraTempProp = curSingle.extraTempProp
      extraTempProp.forEach(item => {
        item.prop.forEach(ele => {
          if(extraTempProp_obj[ele.id].value) {
            ele.value = extraTempProp_obj[ele.id].value
          }
        })
      })
      se.extraTempProp = extraTempProp
      se.property = {}

      this.addForm.singleList[curIndex] = {
        ...curSingle,
        ...se
      }

      this.addForm.singleList = [...this.addForm.singleList]

      // 校验
      let validArr = ['singleName','singleCode','colorArr','sizeArrSelect', 'richContent']
      validArr.forEach(key => {
        this.$refs.addForm.validateField('singleList.' + curIndex + '.' + key)
      })

    },

    
    onSearch(value) {
      console.log(value);
      value = value.trim()
      if (value === '') {
        this.$message.error('请输入搜索关键字')
        return
      }
      this.goodsAndsingleList()
    },
    onPageChange(pageNumber) {
      console.log('Page: ', pageNumber)
      this.quotePage.current = pageNumber
      this.goodsAndsingleList()
    },
    onQuoteChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },

    // 属性数据转化后保存
    transferPropData(list) {
      return list.map(c => {
        let showText = c.value
        if (c.type == 1) showText = c.value + c.unit
        if (c.type == 4 || c.type == 5) showText = c.value.join('、')
        return {
          id: c.id,
          key: c.key,
          value: c.value,
          unit: c.unit,
          type: c.type,
          showText
        }
      })
    },
    // 保存数据
    saveHandle() {
      console.log(this.addForm)
      this.$refs.addForm.validate(valid => {
        if (valid) {
          let priceNotFinishFlag = this.addForm.singleList.some(single => {
            return single.skusChart.some(sku => {
              return sku.list.some(ele => ele.tagPrice == '')
            })
          })
          if (priceNotFinishFlag) {
            this.$message.error('销售规格：吊牌价格为必填')
            return
          }
          if (this.addForm.singleList.length > 1) {
            this.optimizeVisible = true
            return;
          }
          this.finalSubmit()
        } 
        // else {
        //   this.$message.error('校验不通过，请检查表单')
        // }
      })
    },
    // 完善信息确认
    optimizeOk() {
      let name = this.optimizeForm.name.trim()
      let code = this.optimizeForm.code.trim()
      if (name == '') {
        this.$message.error('请输入商品信息')
        return false
      }
      if (code == '') {
        this.$message.error('请输入商品型号')
        return false
      }
      this.optimizeVisible = false
      this.finalSubmit()
    },
    // 最终提交新增
    finalSubmit() {
      this.$showLoading()
      let brand = this.addForm.brand.trim()
      let category
      let categoryId
      if (this.addForm.editCategory) {
        category= this.addForm.category.slice(-1)
        categoryId = category[0]
      } else {
        categoryId = this.detailData.categoryId
      }

      let companyId
      if (this.addForm.company == this.detailData.companyName) {
        companyId = this.detailData.companyId
      } else {
        companyId = this.addForm.company
      }
      
      let images = this.addForm.fileList.map(item => {
        if (item.response && item.response.data && item.response.data[0]) {
          return item.response.data[0]
        } else {
          return item.resUrl || ''
        }
      })
      
      let model = this.addForm.singleList.length > 1 ? 
                  this.optimizeForm.code : 
                  this.addForm.singleList[0].singleCode
      let name = this.addForm.singleList.length > 1 ? 
                  this.optimizeForm.name : 
                  this.addForm.singleList[0].singleName

      let subItemList = this.addForm.singleList.map(item => {
        let subItemColorList = []
        let subItemPriceList = []
        let subItemSizeList = []

        item.skusChart.forEach((color, color_index) => {
          subItemColorList.push({
            image: color.url || '',
            model: color.colorCode,
            name: color.color,
            sequence: color_index + 1
          })
          color.list.forEach((size, size_index) => {
            if(color_index === 0) {
              subItemSizeList.push({
                name: size.size,
                sequence: size_index + 1
              })
            }
            subItemPriceList.push({
              tagPrice: size.tagPrice,
              factoryPrice: size.millPrice,
              retailPrice: size.retailPrice,
              tailPrice: size.tailPrice,
              wholesalePrice: size.wholesalePrice,
            })
          })
        })
        let tempProp = {}
        for (let key in item.tempProp) {
          tempProp[key] = this.transferPropData(item.tempProp[key])
        }
        let extraTempProp = item.extraTempProp.map(e => {
          return {
            id: e.id,
            name: e.name,
            prop: this.transferPropData(e.prop)
          }
        })
        let property = {
          manHour: item.manHour,  // 标准工时
          unitCost: item.unitCost, // 单位成本
          reworkRequired: item.reworkRequired, // 是否需要二次加工 1 是 2 否
          tempProp,
          extraTempProp
        }
        return {
          expandInfo: item.richContent,
          model: item.singleCode,
          name: item.singleName,
          property: JSON.stringify(property),
          subItemColorList,
          subItemSizeList,
          subItemPriceList,
        }
      })
      let params = {
        id: this.id,
        adminId: this.adminId,
        brand,
        categoryId,
        companyId,
        images,
        model,
        name,
        subItemList,
      }
      console.log(params)
      updateGoods(params).then(res => {
        this.$hideLoading()
        console.log(res)
        if (res.code == 200) {
          this.resetForm()
          this.$success({
            title: '提示',
            centered: true,
            content: '编辑商品成功！',
            onOk: () => {
              console.log('我知道了')
              this.$emit('edit-goods-success')
            },
          })
        } else {
          this.$message.error(res.msg || '编辑商品失败！')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err)
      })
    },
    // 重置表单
    resetForm() {
      this.addForm = {
        category: [],
        company: undefined,
        brand: undefined,
        fileList: [],
        singleList: [{
          id: nanoid(),
          // 商品型号
          singleCode: '',
          // 富文本
          richContent: '',
          richDataUpdated: Math.random() + '',

          // 颜色表
          colorArr: [{
            color: '',
            url: '',
            resUrl: '',
            id: nanoid()
          }],

          // 尺码
          sizeArr: this.sizeConfList.map(item => {
            return {
              id: item.categoryId,
              name: item.categoryName,
              isDefault: true
            }
          }),
          sizeArrSelect: [],
          addSizeVal: '',

          // 尺码表 - 销售规格 
          skusChart: [],

          // 标准工时
          manHour: '',
          // 单位成本
          unitCost: '',
          // 是否需要二次加工
          reworkRequired: '',

          // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
          tempProp: deepClone(this.tempProp),
          // 自定义的模板及属性数组
          extraTempProp: deepClone(this.extraTempProp),
          property: {

          }
        }]
      }
    },
  }
}
</script>

<style lang="less" scoped>
.goods-mod {
  border: 1px solid #E6E6E6;
  margin-bottom: 24px;
  .header {
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FAFAFA;
    border-bottom: 1px solid #E6E6E6;
    .tit {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  .quote-btn {
    position: absolute;
    right: 16px;
    top: 24px;
  }
  .main {
    position: relative;
    padding: 32px 20px;
    .main-mod-tit {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 24px;
    }
    .main-mod-wrap {
      min-width: 1000px;
      position: relative;
      .fill-btn {
        position: absolute;
        right: 0;
        top: -32px;
      }
    }
    .divider {
      height: 1px;
      margin: 40px 0 24px;
      background: #E6E6E6;
    }
  }
}
.color-select-pop {
  position: absolute; 
  left: 0; 
  top: 0; 
  z-index: 80; 
  .color-box {
    width: 580px;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    // border: 1px solid #d9d9d9;
    box-shadow: 0 2px 8px #d9d9d9;
    border-radius: 2px;
    .inner {
      padding: 24px 0 0 24px;
    }
    .item {
      display: inline-block;
      margin: 0 8px 24px 0;
      cursor: pointer;
      &:hover {
        color: #0267ED;
      }
    }
  }
  .color-dropdown {
    width: 180px;
    max-height: 300px;
    border-radius: 2px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0 2px 8px #d9d9d9;
    .inner {
      padding: 4px 0;
      margin: 0;
      .item {
        line-height: 22px;
        padding: 5px 12px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          background: #e6f7ff;
        }
      
      }
    }
  }
}

.quote-list {
  margin-top: 16px;
  .q-header {
    height: 48px;
    background: #FAFAFA;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 8px 24px; 
    .td {
      width: 40%; 
      font-weight: 600;
    }
  }
  .q-body {
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: auto;
    .suit {
      .tit {
        display: flex;
        font-size: 12px;
        color: #999999;
        line-height: 18px;
        height: 18px;
        margin: 8px 0;
      }
      padding: 8px 24px;
      border-bottom: 1px solid #eee;
    }
  }
  
  .single {
    display: flex;
    align-items: center;
    .td {
      display: flex;
      align-items: center;
      margin: 4px 0;
    }
    .img-box {
      width: 48px;
      height: 48px;
      background: #f0f0f0;
      margin-right: 12px;
      border-radius: 4px;
      overflow: hidden;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

</style>