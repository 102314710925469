<template>
  <page-head-wrapper>
    <!-- 操作栏 -->
    <template v-slot:handles-btns>
      <a-button size="small" type="primary" class="mr-16" v-if="!edit"
        @click="edit = true"
      >
        <i class="e-icon edit-white"></i>编辑
      </a-button>
      <template v-if="edit">
        <a-button size="small" type="primary" class="mr-16" @click="addSingle"><i class="e-icon add"></i>添加单品</a-button>
        <a-button size="small" type="primary" class="mr-16" @click="saveHandle"><i class="e-icon save"></i>保存</a-button>
        <a-button size="small" @click="edit = false"><i class="e-icon close"></i>取消</a-button>
      </template>
    </template>

    <!-- 详情 -->
    <div class="detail-wrap" v-if="!edit">
      <div class="header-box">
        <div class="pic">
          <a-carousel arrows class="goods-carousel">
            <div slot="prevArrow"
              class="custom-slick-arrow"
              style="left: -40px;zIndex: 1"
            >
              <a-icon type="left-circle" />
            </div>
            <div slot="nextArrow" 
              class="custom-slick-arrow" 
              style="right: -40px;zIndex: 1">
              <a-icon type="right-circle" />
            </div>
            <div v-for="(item, index) in detail.picList" :key="index">
              <img :src="item">
            </div>
          </a-carousel>
        </div>
        <div class="info">
          <h2 class="tit">{{detail.name}}</h2>
          <p class="line"><span>商品类目：</span><strong>{{detail.category}}</strong></p>
          <p class="line"><span>商品型号：</span><strong>{{detail.code}}</strong></p>
          <p class="line"><span>所属企业：</span><strong>{{detail.company}}</strong></p>
          <p class="line"><span>所属品牌：</span><strong>{{detail.brand}}</strong></p>
        </div>
      </div>

      <a-tabs :default-active-key="singleList[0] && singleList[0].key" 
        :tabBarStyle="{
          display: singleList.length <= 1 ? 'none' : 'block',
          marginBottom: '24px'
        }">
        <a-tab-pane :key="single.key" :tab="single.title" v-for="single in singleList">
          <div class="goods-mod">
            <div class="header">
              <h3 class="tit">商品详情</h3>
            </div>
            <div class="main">
              <div class="prop-mod">
                <div class="prop-mod-title">商品属性：</div>
                <div class="prop-mod-content">
                  <div class="prop-item">
                    <div class="prop-label">商品名称：</div>
                    <div class="prop-con">{{single.singleName}}</div>
                  </div>
                  <div class="prop-item">
                    <div class="prop-label">商品型号：</div>
                    <div class="prop-con">{{single.singleCode}}</div>
                  </div>
                  <div class="prop-item" v-for="item in single.tempProp['1']" :key="item.id">
                    <div class="prop-label">{{item.key}}：</div>
                    <div class="prop-con">{{item.showText}}</div>
                  </div>
                </div>
              </div>
              <div class="prop-mod">
                <div class="prop-mod-title">销售属性：</div>
                <div class="prop-mod-content">
                  <div style="margin-bottom: 28px">
                    <table class="sku-table">
                      <thead>
                        <tr class="th">
                          <th>颜色</th>
                          <th>颜色编码</th>
                          <th>尺码</th>
                          <th>出厂价格(元)</th>
                          <th>批发价格(元)</th>
                          <th>零售价格(元)</th>
                          <th>吊牌价格(元)</th>
                          <th>尾货价格(元)</th>
                        </tr>
                      </thead>
                      <tbody v-for="(c_item, c_index) in single.skusChart" :key="c_index">
                        <tr v-for="(s_item, s_index) in c_item.list" :key="s_index">
                          <td v-if="s_index === 0" :rowspan="c_item.list.length">
                            <div class="img-box">
                              <img :src="c_item.url" alt="">
                            </div>
                            <span class="text">{{c_item.color}}</span>
                          </td>
                          <td v-if="s_index === 0" :rowspan="c_item.list.length">
                            <span class="text">{{c_item.colorCode}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.size}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.millPrice}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.wholesalePrice}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.retailPrice}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.tagPrice}}</span>
                          </td>
                          <td>
                            <span class="text">{{s_item.tailPrice}}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="single.skusChart.length == 0" 
                      style="border: 1px solid #f0f0f0; border-top: none; overflow: hidden;"
                    >
                      <a-empty :image="simpleImage" />
                    </div>
                  </div>
                  <div class="prop-item" v-for="item in single.tempProp['2']" :key="item.id">
                    <div class="prop-label">{{item.key}}：</div>
                    <div class="prop-con">{{item.showText}}</div>
                  </div>
                </div>
              </div>
              <div class="prop-mod">
                <div class="prop-mod-title">生产属性：</div>
                <div class="prop-mod-content">
                  <div class="prop-item">
                    <div class="prop-label">标准工时：</div>
                    <div class="prop-con">{{single.manHour}}小时</div>
                  </div>
                  <div class="prop-item">
                    <div class="prop-label">单位成本：</div>
                    <div class="prop-con">{{single.unitCost}}元</div>
                  </div>
                  <div class="prop-item">
                    <div class="prop-label">是否需要二次加工：</div>
                    <div class="prop-con">{{single.reworkRequired}}</div>
                  </div>    
                  <div class="prop-item" v-for="item in single.tempProp['3']" :key="item.id">
                    <div class="prop-label">{{item.key}}：</div>
                    <div class="prop-con">{{item.showText}}</div>
                  </div>
                </div>
              </div>

              <div class="prop-mod" v-for="item in single.extraTempProp" :key="item.id">
                <div class="prop-mod-title">{{item.name}}：</div>
                <div class="prop-mod-content">
                  <div class="prop-item" v-for="ele in item.prop" :key="ele.id">
                    <div class="prop-label">{{ele.key}}：</div>
                    <div class="prop-con">{{ele.showText}}</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="goods-mod">
            <div class="header">
              <h3 class="tit">商品描述</h3>
            </div>
            <div class="main">
              <div class="rich-content" v-html="single.richContent"></div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>

    </div>

    <!-- 编辑 -->
    <edit v-if="edit"
      :id="id"
      :add-single-flag="addSingleFlag"
      :save-goods-flag="saveGoodsFlag" 
      @edit-goods-success="editGoodsSuccess"
    />
  </page-head-wrapper>
</template>

<script>
import { nanoid } from 'nanoid'
import { Empty } from 'ant-design-vue'
import Edit from './edit.vue'
import { goodsDetail } from '@/api/goods'
import { buildThumborUrl } from '@/utils/tools'
export default {
  name: 'static-data-goods-list-detail',
  keep_alive_tab: true,
  components: {
    Edit
  },
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      edit: false,

      // 汉语数字对照
      chineseNumberConf: {
        '1': '一', '2': '二', '3': '三', '4': '四', '5': '五',
        '6': '六', '7': '七', '8': '八', '9': '九', '10': '十'
      },

      // 详情数据
      id: '',
      detailData: {},
      addSingleFlag: 1,
      saveGoodsFlag: 1,
      
      detail: {
        category: '',
        company: '',
        brand: '',
        picList: [],
      },
      singleList: [
        {
          key: 1,
          title: '单品一',
          // 单品名称
          singleName: '',
          // 单品型号
          singleCode: 'sub.model',
          // 富文本
          richContent: '',
          // 标准工时
          manHour: '',
          // 单位成本
          unitCost: '',
          // 是否需要二次加工
          reworkRequired: '',
          // 尺码表 - 销售规格 
          skusChart: [],
          // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
          tempProp: {
            '1': [], '2': [], '3': []
          },
          // 自定义的模板及属性数组
          extraTempProp: [],
        }
      ]
    }
  },
  created() {
    this.id = this.$route.query.id
    this.goodsDetail()
  },
  methods: {
    goodsDetail() {
      this.$showLoading()
      goodsDetail({itemId: this.id}).then(res => {
        console.log(res);
        this.$hideLoading()
        if (res.code == 200) {
          let picList = res.data.images.map(url => buildThumborUrl(url))
          this.detailData = res.data
          this.detail = {
            category: res.data.categoryName,
            company: res.data.companyName,
            brand: res.data.brand,
            picList,
            name: res.data.name,
            code: res.data.model
          }
          let subItemList = res.data.subItemList
          this.singleList = subItemList.map((sub, index) => {
            let property
            try {
              property=JSON.parse(sub.property)
            } catch (error) {
              property = {}
            }

            let subItemColorList = sub.subItemColorList.sort((a,b) => a.sequence - b.sequence)
            let subItemSizeList = sub.subItemSizeList.sort((a,b) => a.sequence - b.sequence)
            let subItemPriceList = sub.subItemPriceList
            let sizeLen = subItemSizeList.length
            let skusChart = subItemColorList.map((color, i) => {
              let list = subItemSizeList.map((size, j) => {
                let pIndex = (sizeLen * i) + j
                return {
                  id: nanoid(),
                  size: size.name,
                  millPrice: subItemPriceList[pIndex].factoryPrice,
                  wholesalePrice: subItemPriceList[pIndex].wholesalePrice,
                  retailPrice: subItemPriceList[pIndex].retailPrice,
                  tagPrice: subItemPriceList[pIndex].tagPrice,
                  tailPrice: subItemPriceList[pIndex].tailPrice,
                }
              })
              return {
                id: nanoid(),
                color: color.name,
                colorCode: color.model,
                thumbUrl: '',
                url: color.image ? buildThumborUrl(color.image, {width: 100, height: 100}) : '',
                list
              }
            })

            return {
              title: '单品' + this.chineseNumberConf[(index+1)+''],
              key: nanoid(),
              // 单品名称
              singleName: sub.name,
              // 单品型号
              singleCode: sub.model,
              // 富文本
              richContent: sub.expandInfo,
              // 标准工时
              manHour: property.manHour,
              // 单位成本
              unitCost: property.unitCost,
              // 是否需要二次加工
              reworkRequired: property.reworkRequired == 1 ? '是' : property.reworkRequired == 2 ? '否' : '-',
              // 尺码表 - 销售规格 
              skusChart,
              // 三个固定模板及属性数组： 1-商品属性 2-销售属性 3-生产属性
              tempProp: property.tempProp,
              // 自定义的模板及属性数组
              extraTempProp: property.extraTempProp,

            }
          })
        } else {
          this.$message.error(res.msg || '网络错误')
        }
      }).catch(err => {
        this.$hideLoading()
        console.error(err);
      })
    },
    addSingle(){
      this.addSingleFlag ++
    },
    saveHandle() {
      this.saveGoodsFlag ++
    },
    editGoodsSuccess() {
      this.edit = false
      this.goodsDetail()
    }
  }
}
</script>

<style lang="less" scoped>
.goods-mod {
  border: 1px solid #E6E6E6;
  margin-bottom: 24px;
  .header {
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    border-bottom: 1px solid #E6E6E6;
    .tit {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }
  .main {
    padding: 30px 20px;
  }
  .rich-content {
    width: 750px;
    margin: 0 auto;
  }
}
.detail-wrap {
  .header-box {
    display: flex;
    margin-bottom: 24px;
    .pic {
      padding: 0 40px;
    }
    .info {
      margin-left: 40px;
      .tit {
        font-size: 22px;
        color: #333333;
        line-height: 30px;
        margin-bottom: 24px;
      }
      .line {
        margin-bottom: 16px;
        line-height: 20px;
        span {
          color: #999999;
        }
        strong {
          margin-left: 28px;
          color: #333333;
          font-weight: normal;
        }
      }
    }
  }

  .prop-mod {
    display: flex;
  }
  .prop-mod-title {
    width: 90px;
    color: #999999;
  }
  .prop-mod-content {
    width: 100%;
    .prop-item {
      display: flex;
      margin-bottom: 28px;
    }
    .prop-label {
      color: #999999;
      margin-right: 24px;
    }
    .prop-con {
      color: #333333;
    }
  }
}



</style>
<style lang="less">
.goods-carousel {
  width: 200px;
  height: 200px;
  .slick-slide {
    text-align: center;
    height: 200px;
    line-height: 200px;
    background: #364d79;
    overflow: hidden;
    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
  }
  .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: #999;
    opacity: 0.3;
    border-radius: 50%;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  .custom-slick-arrow:hover {
    color: #fff;
    background-color: #999;
    opacity: 0.5;
  }
}
</style>